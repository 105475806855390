@media only screen and (max-width: 1024px) {
  .footer {
    flex-direction: column;
    padding: 10px 2%;

    .footer-logo-nav-container {
      flex-direction: column; }

    .logo {
      margin-right: 0; } } }

@media only screen and (max-width: 860px) {
  .footer {
    padding: 12px 2.4%; } }

@media only screen and (max-width: 600px) {
  .footer {
    padding: 15px 2.7%; } }

@media only screen and (max-width: 400px) {
  .footer {
    padding: 18px 3.2%; } }
