.footer {
  min-height: 80px;
  padding: 0 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  .footer-logo-nav-container {
    display: flex;
    align-items: center;
    width: 100%; }

  .logo {
    margin-right: 2%; } }

// Media Queries Footer //
@import '../media/_media_footer';
