@media only screen and (max-width: 1024px) {
  .article-content-image {
    margin: 6% 0; } }

@media only screen and (max-width: 700px) {
  .article-content-image {
    margin: 7% 0; } }

@media only screen and (max-width: 600px) {
  .article-content-image .article-content-image-info {
    flex-direction: column;

    .article-content-image-source {
      text-align: right;
      margin-top: 3%; } } }

@media only screen and (max-width: 500px) {
  .article-content-image {
    margin: 8% 0;

    figcaption.article-content-image-info {
      .article-content-image-description, p {
        padding: 0; } } } }

@media only screen and (max-width: 400px) {
  .article-content-image {
    margin: 9% 0; } }

@media only screen and (max-width: 360px) {
  .article-content-image .article-content-image-info {
    padding: 5%;

    .article-content-image-source {
      margin-top: 5%; } } }
