@media only screen and (max-width: 1024px) {
  .section-heading {
    padding: 2.5% 0 2% 0;
    margin-bottom: 7%; } }

@media only screen and (max-width: 700px) {
  .section-heading {
    padding: 3.5% 0 3% 0;
    margin-bottom: 8%; } }

@media only screen and (max-width: 500px) {
  .section-heading {
    padding: 4.5% 0 4% 0;
    margin-bottom: 9%; } }

@media only screen and (max-width: 400px) {
  .section-heading {
    padding: 5.5% 0 5% 0;
    margin-bottom: 10%; } }
