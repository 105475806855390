@media only screen and (max-width: 1024px) {
  .back-container {
    margin: 3% 0 7% 0; } }

@media only screen and (max-width: 700px) {
  .back-container {
    margin: 4% 0 8% 0; } }

@media only screen and (max-width: 500px) {
  .back-container {
    padding: 0 3%;
    margin: 5% 0 9% 0; } }

@media only screen and (max-width: 450px) {
  .back-container {
    margin: 6% 0 10% 0; } }

@media only screen and (max-width: 360px) {
  .back-container {
    padding: 0 5%; } }
