.back-to-top-button {
  display: block;
  background-color: rgba(0,0,0,0.7);
  color: white;
  font-size: 0.8rem;
  position: fixed;
  right: 3%;
  bottom: 3%;
  padding: 0.8em;
  border-radius: 9999px;
  opacity: 0;
  visibility: hidden;

  &:hover {
    color: $orange; }

  &.show {
    opacity: 1;
    visibility: visible; } }
