@media only screen and (max-width: 1700px) {
  .article-preview.article-preview--tag {
    width: 45%; } }

@media only screen and (max-width: 1600px) {
  .article-preview {
    width: 100%;
    margin-bottom: 58px;

    &:last-child {
      margin-bottom: 0; }

    &.article-preview--all, &.article-preview--all:nth-last-child(2), &.article-preview--all:nth-last-child(2):nth-child(even), &.article-preview--tag {
      margin-bottom: 70px; }

    &.article-preview--tag {
      width: 80%; } } }

@media only screen and (max-width: 1024px) {
  .article-preview {
    margin-bottom: 45px;

    &.article-preview--all, &.article-preview--all:nth-last-child(2), &.article-preview--all:nth-last-child(2):nth-child(even), &.article-preview--tag {
      margin-bottom: 50px; }

    &.article-preview--tag {
      width: 100%; } } }

@media only screen and (max-width: 700px) {
  .article-preview {
    margin-bottom: 38px;

    &.article-preview--all, &.article-preview--all:nth-last-child(2), &.article-preview--all:nth-last-child(2):nth-child(even), &.article-preview--tag {
      margin-bottom: 45px; } } }

@media only screen and (max-width: 400px) {
  .article-preview {
    margin-bottom: 36px;

    &.article-preview--all, &.article-preview--all:nth-last-child(2), &.article-preview--all:nth-last-child(2):nth-child(even), &.article-preview--tag {
      margin-bottom: 40px; } } }
