@media only screen and (max-width: 1024px) {
  .article-header {
    margin-bottom: 6%;

    .article-heading {
      margin: 0 0 6% 0; }

    .article-image {
      background: {
        attachment: scroll;
        position: center center; } } } }

@media only screen and (max-width: 700px) {
  .article-header {
    margin-bottom: 7%;

    .article-heading {
      margin: 0 0 7% 0; } } }

@media only screen and (max-width: 500px) {
  .article-header {
    margin-bottom: 8%;

    .article-heading {
      margin: 0 0 8% 0;
      padding: 0 3%; } } }

@media only screen and (max-width: 400px) {
  .article-header {
    margin-bottom: 9%;

    .article-heading {
      margin: 0 0 9% 0;
      padding: 0 5%; } } }
