@media only screen and (max-width: 1800px) {
  .tag {
    width: 42%; } }

@media only screen and (max-width: 1600px) {
  .tag.tag--tag-page {
    width: 47%; } }

@media only screen and (max-width: 1400px) {
  .tag.tag--tag-page {
    width: 52%; } }

@media only screen and (max-width: 1300px) {
  .tag {
    width: 45%;

    &.tag--large {
      width: 60%; } } }

@media only screen and (max-width: 1200px) {
  .tag.tag--tag-page {
    width: 60%; } }

@media only screen and (max-width: 1024px) {
  .tag {
    &.tag--large {
      width: 65%; }

    &.tag--article {
      margin: 30px 30px 0 0; }

    &.tag--related-tags {
      margin: 21px; }

    &.tag--tag-page {
      width: 70%; } } }

@media only screen and (max-width: 950px) {
  .tag {
    width: 49%; } }

@media only screen and (max-width: 900px) {
  .tag {
    &.tag--large {
      width: 70%; }

    &.tag--tag-page {
      width: 78%; } } }

@media only screen and (max-width: 860px) {
  .tag {
    width: 52%;

    &.tag--tag-page {
      width: 82%; } } }

@media only screen and (max-width: 780px) {
  .tag {
    width: 57%;

    &.tag--large {
      width: 76%; }

    &.tag--tag-page {
      width: 88%; } } }

@media only screen and (max-width: 720px) {
  .tag {
    &.tag--large {
      width: 80%; }

    &.tag--article {
      margin: 25px 25px 0 0; }

    &.tag--related-tags {
      margin: 19px; }

    &.tag--tag-page {
      width: 95%; } } }

@media only screen and (max-width: 650px) {
  .tag.tag--tag-page {
    width: 100%; } }

@media only screen and (max-width: 600px) {
  .tag {
    width: 62%;

    &.tag--large {
      width: 85%; }

    &.tag--tag-page {
      border-radius: 0; } } }

@media only screen and (max-width: 550px) {
  .tag {
    width: 68%;

    &.tag--large {
      width: 92%; } } }

@media only screen and (max-width: 500px) {
  .tag, .tag.tag--large {
    width: 100%;
    border-radius: 0; }

  .tag.tag--article {
    border-radius: 9999px; } }
