@media only screen and (max-width: 1600px) {
  .blog-section .article-previews {
    flex-direction: column; } }

@media only screen and (max-width: 1280px) {
  .about-section {
    .about-text {
      width: 100%; } }

  .resources-section {
    .resources-container .resource {
      width: 48%;
      margin-bottom: 5%;

      &:last-child {
        width: 100%;
        margin-bottom: 0; } }

    .resources-info {
      width: 80%; } } }

@media only screen and (max-width: 1024px) {
  main > section {
    padding: 0 6% 7% 6%; }

  .welcome-section {
    height: calc(100vh - 120px);
    background-attachment: scroll;

    .welcome-section-overlay {
      padding-top: 7%; } }

  .blog-section, .resources-section {
    padding: 0 14% 7% 14%; }

  .resources-section {
    .resources-container {
      margin-bottom: 6%;
      flex-direction: column;

      .resource {
        width: 100%;
        margin-bottom: 6%; } }

    .resources-info {
      width: 90%; } }

  .contact-section {
    .text, .social-container {
      margin-bottom: 6%; }

    .social-container .social-item {
      width: 50%;

      &.twitter {
        width: 100%; } } } }

@media only screen and (max-width: 860px) {
  .welcome-section {
    height: calc(100vh - 126px); }

  .contact-section {
    .social-container .social-item {
      width: 100%; }

    .email {
      font-size: 2.8rem; } } }

@media only screen and (max-width: 700px) {
  main > section {
    padding: 0 7% 8% 7%; }

  .welcome-section {
    .welcome-section-overlay {
      padding-top: 8%; } }

  .blog-section, .resources-section {
    padding: 0 10% 8% 10%; }

  .resources-section .resources-container {
    margin-bottom: 7%;

    .resource {
      margin-bottom: 7%; } }

  .contact-section {
    .text, .social-container {
      margin-bottom: 7%; }

    .email {
      font-size: 2.4rem; } } }

@media only screen and (max-width: 600px) {
  .welcome-section {
    height: calc(100vh - 135px);

    .welcome-text {
      font-size: 3rem; }

    h1 {
      font-size: 3.5rem; } }

  .blog-section {
    padding: 0 7% 8% 7%; }

  .contact-section {
    padding: 0 0 8% 0;

    .email {
      font-size: 2.2rem; } } }

@media only screen and (max-width: 500px) {
  main > section {
    padding: 0 8% 9% 8%; }

  .welcome-section {
    .welcome-section-overlay {
      padding-top: 9%; }

    .welcome-text {
      font-size: 2.5rem; }

    h1 {
      font-size: 3rem; } }

  .about-section .about-text {
    img {
      width: auto;
      height: 150px;
      float: none;
      margin: 0 auto 5% auto; } }

  .blog-section, .resources-section, .contact-section {
    padding: 0 0 9% 0; }

  .resources-section {
    .resources-container, .resources-container .resource {
      margin-bottom: 8%; } }

  .contact-section {
    .text, .social-container {
      margin-bottom: 8%; }

    .email {
      font-size: 1.8rem; } } }

@media only screen and (max-width: 450px) {
  .contact-section .text {
    padding: 0 5%; } }

@media only screen and (max-width: 400px) {
  main > section {
    padding: 0 9% 10% 9%; }

  .welcome-section {
    height: calc(100vh - 144px);

    .welcome-section-overlay {
      padding-top: 10%; } }

  .about-section .about-text {
    img {
      margin: 0 auto 9% auto; } }

  .blog-section, .resources-section, .contact-section {
    padding: 0 0 10% 0; }

  .resources-section {
    .resources-container, .resources-container .resource {
      margin-bottom: 9%; } }

  .contact-section {
    .text, .social-container {
      margin-bottom: 9%; }

    .email {
      font-size: 1.6rem; } } }

@media only screen and (max-width: 360px) {
  .about-section {
    padding: 0 5% 10% 5%; } }
