.article-header {
  margin-bottom: 5%;

  .article-heading {
    margin: 0 0 5% 0;
    text-align: center;
    color: $blue;
    font: {
      size: 2rem;
      weight: normal; } }

  .article-image {
    background: {
      repeat: no-repeat;
      size: cover;
      attachment: fixed;
      position: center 0px; }
    width: 100%;
    height: 400px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.3); }

  &.article-header--tag {
    margin-bottom: 3%; } }

// Media Queries Article Header //
@import '../media/_media_article-header';
