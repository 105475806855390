.cta {
  display: block;
  background-color: $blue;
  color: $white-gray;
  padding: 0.4em;
  width: 20%;
  margin: 5% auto auto auto;
  border-radius: 9999px;
  font-size: 1.8rem;
  text-align: center;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.3);

  &:hover {
    background-color: $orange; }

  &.cta--large {
    width: 23%; }

  &.cta--read-more {
    font-size: 1.05rem;
    margin: 0;
    width: 23%; }

  &.cta--learn-more {
    @extend .cta--read-more;
    width: 27%; }

  &.cta--small {
    width: 18%; } }

// Media Queries CTA //
@import '../media/_media_cta';
