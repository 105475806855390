// Vars //
@import '../helpers/_vars';

// Secondary Page Styles //
// About Section //
.about-section {
  .about-text {
    width: 70%;
    margin: auto;

    img {
      width: 150px;
      float: left;
      margin: 0 3% 1.2% 0; } } }

// Blog Section //
.blog-section {
  padding: 0 13% 6% 13%;

  .article-previews {
    display: flex;
    justify-content: space-between; } }

// Resources Section //
.resources-section {
  padding: 0 13% 6% 13%;

  .resources-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5%;

    .resource {
      display: block;
      width: 28%;
      color: white;
      position: relative;
      box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
      overflow: hidden;

      &:hover img {
        transform: scale(1.05); }

      img {
        width: 100%;
        transition: all .3s ease-in-out; } }

    .resource-overlay {
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2%; }

    .resource-heading {
      font-size: 1.4rem;
      font-weight: normal;
      text-align: center; } }

  .resources-info {
    width: 70%;
    margin: auto; } }

// Contact Section //
.contact-section {
  .text, .social-container {
    margin-bottom: 5%; }

  .text {
    font-size: 1.6rem;
    text-align: center; }

  .social-container {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.3);

    .social-item {
      display: block;
      width: 33.333%;

      &.twitter {
        background-color: $twitter-color; }

      &.github {
        background-color: $github-color; }

      &.codepen {
        background-color: $codepen-color; }

      &:hover .social-network .social-icon::after {
        opacity: 1;
        visibility: visible; } }

    .social-network {
      display: block;
      color: white;
      text-align: center;
      font-size: 1.5rem;
      font-weight: normal;
      margin: 10% auto;
      transition: all .3s linear;

      .social-icon {
        position: relative;
        display: block;
        width: 120px;
        height: 120px;
        background: {
          repeat: no-repeat;
          size: cover; }
        margin: auto auto 2% auto;

        &::after {
          content: '';
          display: block;
          width: 120px;
          height: 120px;
          background: {
            repeat: no-repeat;
            size: cover; }
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: all .3s ease-in-out; } } }

    .twitter {
      &:hover {
        background-color: white;

        .social-network {
          color: $twitter-color; } }

      .social-network .social-icon {
        background-image: url('../img/icons/twitter-icon.svg');

        &::after {
          background-image: url('../img/icons/twitter-icon-reverse.svg'); } } }

    .github {
      &:hover {
        background-color: black; }

      .social-network .social-icon {
        background-image: url('../img/icons/github-icon.svg');

        &::after {
          background-image: url('../img/icons/github-icon-reverse.svg'); } } }

    .codepen {
      &:hover {
        background-color: white;

        .social-network {
          color: $codepen-color; } }

      .social-network .social-icon {
        background-image: url('../img/icons/codepen-icon.svg');

        &::after {
          background-image: url('../img/icons/codepen-icon-reverse.svg'); } } } }

  .email {
    display: block;
    color: $blue;
    font-weight: bold;
    font-size: 3.5rem;
    text-align: center;

    &:hover {
      color: $orange; } } }

// Modules //
@import '../modules/_modules';

// Partials //
@import '../partials/_footer';

// Media Queries Index Page //
@import '../media/_media_index';
