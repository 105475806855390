@media only screen and (max-width: 1800px) {
  .cta.cta--learn-more {
    width: 30%; } }

@media only screen and (max-width: 1700px) {
  .cta {
    width: 25%;

    &.cta--large, &.cta--read-more {
      width: 28%; }

    &.cta--learn-more {
      width: 32%; }

    &.cta--small {
      width: 20%; } } }

@media only screen and (max-width: 1600px) {
  .cta {
    &.cta--read-more {
      width: 18%; }

    &.cta--learn-more {
      width: 34%; } } }

@media only screen and (max-width: 1450px) {
  .cta.cta--learn-more {
    width: 36%; } }

@media only screen and (max-width: 1400px) {
  .cta {
    width: 30%;

    &.cta--large {
      width: 33%; }

    &.cta--learn-more {
      width: 18%; } } }

@media only screen and (max-width: 1300px) {
  .cta.cta--learn-more {
    width: 20%; } }

@media only screen and (max-width: 1200px) {
  .cta {
    width: 35%;

    &.cta--large {
      width: 39%; }

    &.cta--learn-more {
      width: 22%; } } }

@media only screen and (max-width: 1024px) {
  .cta {
    width: 40%;
    margin: 6% auto auto auto;

    &.cta--large {
      width: 43%; }

    &.cta--read-more {
      width: 22%; }

    &.cta--learn-more {
      width: 24%; } } }

@media only screen and (max-width: 950px) {
  .cta {
    &.cta--large {
      width: 50%; }

    &.cta--learn-more {
      width: 27%; } } }

@media only screen and (max-width: 860px) {
  .cta {
    width: 50%;

    &.cta--large {
      width: 53%; }

    &.cta--read-more {
      width: 25%; }

    &.cta--learn-more {
      width: 30%; }

    &.cta--small {
      width: 22%; } } }

@media only screen and (max-width: 790px) {
  .cta {
    &.cta--large {
      width: 58%; }

    &.cta--read-more {
      width: 30%; }

    &.cta--learn-more {
      width: 33%; }

    &.cta--small {
      width: 24%; } } }

@media only screen and (max-width: 700px) {
  .cta {
    width: 60%;
    margin: 7% auto auto auto;

    &.cta--large {
      width: 65%; } } }

@media only screen and (max-width: 650px) {
  .cta {
    &.cta--large {
      width: 72%; }

    &.cta--learn-more {
      width: 35%; } } }

@media only screen and (max-width: 600px) {
  .cta {
    width: 70%;

    &.cta--large {
      width: 82%; }

    &.cta--small {
      width: 26%; } } }

@media only screen and (max-width: 550px) {
  .cta {
    &.cta--read-more {
      width: 35%; }

    &.cta--learn-more {
      width: 38%; }

    &.cta--small {
      width: 29%; } } }

@media only screen and (max-width: 500px) {
  .cta {
    width: 80%;
    margin: 8% auto auto auto;

    &.cta--large {
      width: 84%; }

    &.cta--small {
      width: 32%; } } }

@media only screen and (max-width: 450px) {
  .cta {
    width: 90%;

    &.cta--large {
      width: 89%; }

    &.cta--learn-more {
      width: 42%; }

    &.cta--small {
      width: 36%; } } }

@media only screen and (max-width: 380px) {
  .cta {
    margin: 15% auto auto auto;

    &.cta--large {
      width: 92%; }

    &.cta--read-more {
      width: 38%; }

    &.cta--learn-more {
      width: 44%; } } }
