@media only screen and (max-width: 1280px) {
  blockquote p::before {
    width: 12%; } }

@media only screen and (max-width: 1150px) {
  blockquote p::before {
    width: 15%; } }

@media only screen and (max-width: 1000px) {
  blockquote p::before {
    width: 20%; } }

@media only screen and (max-width: 900px) {
  blockquote {
    position: relative;
    padding: 4%;

    &::before {
      content: "\201C";
      font: {
        size: 4rem;
        family: $secondary-font;
        weight: bold;
        style: italic; }
      line-height: 1em;
      position: absolute;
      top: -0.2em;
      left: -0.2em; }

    p::before {
      display: none; } } }

@media only screen and (max-width: 650px) {
  blockquote {
    padding: 5%; } }

@media only screen and (max-width: 500px) {
  blockquote {
    margin-bottom: 5%;

    cite {
      margin-top: 2%; } } }

@media only screen and (max-width: 400px) {
  blockquote {
    margin-bottom: 8%;
    padding: 6%;

    cite {
      margin-top: 3%; } } }

@media only screen and (max-width: 360px) {
  blockquote {
    padding: 7%; } }
