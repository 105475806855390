@media only screen and (max-width: 1024px) {
  .nav {
    width: 100%;
    margin-top: 10px;

    &.nav--main {
      ul {
        flex-direction: column;
        width: 100%;
        opacity: 0;
        position: absolute;
        top: 100%;
        left: -100%;
        z-index: 99;
        transition: all .4s ease-in-out;
        background: white; }

      li {
        padding: 0; }

      a {
        text-align: left;
        padding: 0.8% 2%;
        border-bottom: 1px solid $white-gray; }

      &.open {
        ul {
          opacity: 1;
          left: 0; }

        .navbar {
          .hamburger-icon {
            opacity: 0; }

          .hamburger-icon_top {
            transform: rotate(45deg); }

          .hamburger-icon_bottom {
            transform: rotate(-45deg); } } }

      .navbar {
        display: block; } } }

  &.nav--footer, &.nav--legal {
    ul {
      justify-content: center; } } }

@media only screen and (max-width: 860px) {
  .nav {
    margin-top: 12px;

    &.nav--main a {
      padding: 1.2% 2.4%; } } }

@media only screen and (max-width: 650px) {
  .nav.nav--footer {
    display: none; } }

@media only screen and (max-width: 600px) {
  .nav {
    margin-top: 15px;

    &.nav--main a {
      padding: 1.5% 2.7%; } } }

@media only screen and (max-width: 400px) {
  .nav {
    margin-top: 18px;

    &.nav--main a {
      padding: 2% 3.2%; } } }
