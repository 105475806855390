blockquote {
  background-color: white;
  padding: 3%;
  margin-bottom: 4%;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
  border-left: 10px solid $light-blue;

  p {
    display: flex;

    &::before {
      content: "\201C";
      font: {
        size: 4rem;
        family: $secondary-font;
        weight: bold;
        style: italic; }
      line-height: 1em;
      width: 10%;
      position: relative;
      top: -0.2em; } }

  cite {
    display: block;
    text-align: right;
    margin-top: 1%;

    &::before {
      content: "\2014";
      font: {
        weight: bold; }
      padding: 0 1%; } } }

// Media Queries Blockquote //
@import '../media/_media_blockquote';
