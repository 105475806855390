.table {
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.3);

  caption {
    display: block;
    font: {
      size: 1.2rem;
      family: $secondary-font; }
    padding: 1.2em; }

  thead, thead tr, tbody, tbody tr {
    display: flex; }

  thead {
    tr, th {
      flex: 1; }

    th {
      display: block;
      min-width: 100px;
      padding: 0.8em;
      background-color: $light-blue;
      color: $dark-blue; } }

  tbody {
    flex-direction: column;

    tr {
      &:nth-child(even) td {
        background-color: $white-gray; }

      &:hover td {
        color: $dark-blue; } }

    td {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      flex: 1;
      text-align: center;
      padding: 0.8em;
      vertical-align: middle;
      transition: all .2s linear; } } }

// Media Queries Table //
@import '../media/_media_table';
