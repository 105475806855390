.article-preview {
  display: flex;
  flex-direction: column;
  width: 45%;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.3);

  header {
    position: relative;
    overflow: hidden;

    &:hover img {
      transform: scale(1.05); }

    img {
      width: 100%;
      transition: all .3s ease-in-out; } }

  &.article-preview--all, &.article-preview--tag {
    margin-bottom: 55px; }

  &.article-preview--all {
    &:nth-last-child(-n+2) {
      margin-bottom: 0; }

    &:nth-last-child(2):nth-child(even) {
      margin-bottom: 55px; } }

  &.article-preview--tag {
    width: 70%;

    &:last-child {
      margin-bottom: 0; } }

  .article-preview-overlay {
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2%; }

  .article-preview-heading {
    color: white;
    text-align: center;
    font-size: 1.4rem;
    font-weight: normal;
    margin: 0;
    padding: 0; }

  .article-preview-text, footer {
    padding: 1.2em; }

  .article-preview-text {
    flex: 1 0 auto; }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    time {
      font-size: 0.9rem;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 30px;
        height: 30px;
        background: {
          image: url('../img/icons/time-icon.svg');
          repeat: no-repeat;
          size: cover; } } } } }

// Media Queries Article Preview //
@import '../media/_media_article-preview';
